import React from "react";
import { Services } from "./services";
import styled from "styled-components";
import { Container } from "../../../global";
import { Heading, Box, Text } from "rebass/styled-components";
import { useLocationCountry } from "../../../../hooks";
const DoMore = () => {
  const { isPakistan } = useLocationCountry();
  return (
    <Container id="do-more">
      <Box maxWidth="60rem" mx="auto" mb="6rem" mt="5rem">
        <Heading variant="heading" textAlign="center" mb="-0.5rem">
          {isPakistan ? "OPay... Beyond Banking" : "Making Financial Services More Accessible For You"}
        </Heading>
      </Box>
      <Wrapper>
        <StyledBackground>
          <Services />
        </StyledBackground>
      </Wrapper>
      <LowestFeesSection width={["", null, null, null, "978px"]}>
        <Box mb="1.5rem" width="100%">
          <Text
            color="secondary"
            fontSize={["35px", "45px", null, "80px"]}
            lineHeight={["40px", null, null, "80px"]}
            sx={{
              fontFamily: "Graphik",
              fontStyle: "normal",
              fontWeight: "200",
            }}
            textAlign={["center", "center", "center"]}
          >
            Easy, fast transactions
          </Text>
        </Box>
        <Text
          variant="subHeaderBody"
          fontSize={["14px", "14px", "14px", "18px", "18x"]}
          as="p"
          lineHeight={["1.9", "1.9", "27px", "36px"]}
          px={["10px", null, null, "80px"]}
          textAlign={["center", "center", "center"]}
        >
          Goodbye to queues. OPay is the easiest and fastest way to make transactions and manage your finances without
          standing in long bank queues.
        </Text>
      </LowestFeesSection>
    </Container>
  );
};
export default DoMore;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
`;

const StyledBackground = styled.div`
  background: #f8fbff;
  padding: 30px;

  ${({ theme }) => theme.mq.lg`
    border-radius: 90px;
    padding: 30px;
  `}
`;

const LowestFeesSection = styled(Box)`
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 40px 20px 30px;
  position: relative;
  z-index: -1;

  &:before {
    background-color: ${({ theme }) => theme.colors.accent};
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: -4px;
    width: 100%;
  }

  &:after {
    background-color: ${({ theme }) => theme.colors.white.regular};
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: 0px;
    width: 100%;
  }

  svg {
    width: 100%;
  }

  ${({ theme }) => theme.mq.lg`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
   margin: 0 auto;
  `}
`;
