import React from "react";

import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";

import Header from "../../components/sections/home-new/header";
import Footer from "../../components/sections/footer";
import SecurityAndPrivacy from "../../components/sections/home-new/security-and-privacy";

import ArrowIndicator from "../../components/sections/arrowIndicator";
import DoMore from "../../components/sections/home-new/doMore";
import Inquiries from "../../components/sections/home-new/inquiries";
import BetterLife from "../../components/sections/better-life";
import OurPartners from "../../components/sections/home-new/our-partners";
import OpayCard from "../../components/sections/home-new/opay-card";
import DownloadAppSection from "../../components/sections/home-new/download-app";

const IndexPage = () => (
  <Layout>
    <SEO title="OPay Pakistan" />
    <Navigation background="white" />
    <Header />
    <ArrowIndicator />
    <DoMore />
    <BetterLife />
    <OpayCard />
    <SecurityAndPrivacy />
    <DownloadAppSection />
    <OurPartners />
    <Inquiries />
    <Footer />
  </Layout>
);

export default IndexPage;
