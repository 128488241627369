import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import Img from "gatsby-image";

export const Wrapper = styled(Flex)`
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  margin-bottom: 3rem;

  ${({ theme }) => theme.mq.lg`
    margin-bottom: 20rem;
    `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ProductImage = styled(Img)`
  background-color: ${({ theme }) => theme.colors.primary}29;
  border-radius: 1rem;
  height: ${({ height }) => height}px;
  max-height: 30rem;
  object-fit: cover;
  overflow: hidden;
  width: 100%;

  ${({ theme }) => theme.mq.lg`
  max-height: unset;
  `}
`;
