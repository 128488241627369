import React from "react";
import styled from "styled-components";
import { Heading } from "rebass/styled-components";
import { Container } from "../../global";
import BetwayLogo from "../../../images/logos/betway.svg";
import FlutterwaveLogo from "../../../images/logos/flutterwave.svg";
import InterswitchLogo from "../../../images/logos/interswitch.svg";
import ThriveAgricLogo from "../../../images/logos/thrive-agric.svg";
import GoogleLogo from "../../../images/logos/google.svg";
import WhoLogo from "../../../images/logos/who.svg";
import ULessonLogo from "../../../images/logos/ulesson.svg";
import RemitOneLogo from "../../../images/logos/remit-one.svg";
import MTNLogo from "../../../images/logos/mtn.svg";
import NIBSSLogo from "../../../images/logos/nibss.png";
import { RandomPosition } from "../../common/randomPosition";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import { useLocationCountry } from "../../../hooks";

const OurPartners = () => {
  const { isPakistan } = useLocationCountry();

  const [xs, sm] = useBreakpoints();
  return (
    <Container>
      <Heading variant="heading" mb={50}>
        Our Partners
      </Heading>
      <LogoDisplay>
        {xs || sm ? (
          <>
            <LogoImage src={NIBSSLogo} />
            <LogoCover as={FlutterwaveLogo} style={{ alignSelf: "flex-end" }} />
            <LogoCover as={ThriveAgricLogo} style={{ alignSelf: "center" }} />
            <LogoCover className="inv" as={BetwayLogo} style={{ alignSelf: "flex-end" }} />
            <LogoCover as={InterswitchLogo} />
          </>
        ) : (
          <>
            <RandomPosition>
              <StyledImageBg>
                <LogoImage src={NIBSSLogo} />
              </StyledImageBg>
            </RandomPosition>

            <RandomPosition>
              <LogoCover as={FlutterwaveLogo} />
            </RandomPosition>

            <RandomPosition>
              <LogoCover as={ThriveAgricLogo} />
            </RandomPosition>

            <RandomPosition>
              <LogoCover as={MTNLogo} />
            </RandomPosition>

            <RandomPosition>
              <LogoCover as={InterswitchLogo} />
            </RandomPosition>

            <RandomPosition>
              <LogoCover as={RemitOneLogo} />
            </RandomPosition>

            <RandomPosition>
              <LogoCover as={WhoLogo} />
            </RandomPosition>

            {isPakistan ? null : (
              <RandomPosition>
                <LogoCover as={BetwayLogo} />
              </RandomPosition>
            )}

            <RandomPosition>
              <LogoCover as={GoogleLogo} />
            </RandomPosition>

            <RandomPosition>
              <LogoCover as={ULessonLogo} />
            </RandomPosition>
          </>
        )}
      </LogoDisplay>
    </Container>
  );
};

export default OurPartners;

const LogoDisplay = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;

  ${({ theme }) => theme.mq.lg`
  display: flex;
    flex-direction: row;
    height: 300px;
    margin-bottom: 50px;
    justify-content: center;
    z-index: 2;

    & > div {
      display: flex;
      justify-content: center;
      width: 20%;

      &:nth-child(n + 6) {
        justify-content: flex-start;
      }
    }
  `}
`;

const LogoCover = styled.svg`
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  height: 120px;
  width: 120px;
  padding: 10px;

  ${({ theme }) => theme.mq.lg`
  padding: 5px;
  `}
`;

const LogoImage = styled.img`
  height: 120px;
  width: 120px;
  padding: 10px;
  object-fit: contain;
`;

const StyledImageBg = styled.div`
  background-color: ${({ theme }) => theme.colors.white.regular};
  width: 122px;
  height: 122px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  border-radius: 50%;

  ${({ theme }) => theme.mq.lg`
        padding: 5px;
    `}
`;
