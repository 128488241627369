import React from "react";
import { Link } from "gatsby";
import { Box, Button, Flex, Text } from "rebass/styled-components";
import PropTypes from "prop-types";
import { useMeasure } from "react-use";
import { Wrapper, ImageWrapper, ProductImage } from "./style";
import { useLocationCountry } from "../../../hooks";

export const ProductCard = ({ image, imageOrder, link, subtitle, title, pkimage }) => {
  const [imageRef, { width }] = useMeasure();

  const height = (perc = 65) => {
    return (width * perc) / 100;
  };

  const { isPakistan } = useLocationCountry();

  return (
    <Wrapper>
      <Flex
        flexDirection={["column", null, null, "row"]}
        justifyContent={["flex-start", null, null, imageOrder ? "flex-end" : "flex-start"]}
        width="100%"
      >
        <Box width={[1, null, null, "60%"]}>
          <Box pt={[0, null, null, "8rem"]}>
            <ImageWrapper ref={imageRef}>
              <ProductImage
                alt={title}
                height={height()}
                objectFit="cover"
                fluid={isPakistan ? pkimage : image}
                width="100%"
              />
            </ImageWrapper>
          </Box>
        </Box>
        <Box
          mt={["-1rem", null, null, 0]}
          width={[1, null, null, "50%"]}
          sx={{
            marginTop: link ? "" : "45px !important",
            position: ["relative", null, null, "absolute"],
            [imageOrder ? "left" : "right"]: 0,
            background: "white",
            borderRadius: "16px",
          }}
        >
          <Box variant="productCard">
            <Text
              variant="heading"
              fontWeight="bold"
              textAlign={["left", null, null, "left"]}
              fontSize={["2xl", null, null, "3rem"]}
              mb={["1rem", null, null, "1.5rem"]}
              sx={{ textDecoration: "none", whiteSpace: ["none", "none", "none", "none", "pre-line"] }}
            >
              {title}
            </Text>

            <Text
              variant="body"
              color="dark80"
              sx={{ whiteSpace: ["none", "none", "none", "none", "pre-line"] }}
              textAlign={["left", null, null, "left"]}
              lineHeight={["30px", null, null, "36px"]}
            >
              {subtitle}
            </Text>

            {link ? (
              <Button mt="3.6rem" textAlign="center" variant="primary" style={{ cursor: "pointer" }}>
                <Link to={link} style={{ textDecoration: "none" }}>
                  Learn more
                </Link>
              </Button>
            ) : null}
          </Box>
        </Box>
      </Flex>
    </Wrapper>
  );
};

ProductCard.propTypes = {
  image: PropTypes.object.isRequired,
  pkimage: PropTypes.object.isRequired,
  imageOrder: PropTypes.number,
  link: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

ProductCard.defaultProps = {
  imageOrder: 1,
};
