import styled from "styled-components";
import { Flex, Box } from "rebass/styled-components";
import bg from "../../../../images/inquires.png";
import pkBg from "../../../../images/inquiries-pk.png";

export const SectionWrapper = styled.div`
  margin-bottom: 20px;

  ${({ theme }) => theme.mq.lg`
   margin-bottom: 170px;
  }  
`};
`;

export const Wrapper = styled(Box)`
  background-image: linear-gradient(-110.57deg, rgba(228, 255, 248, 0.1) -2.17%, #e4fff8 99.02%);

  ${({ theme, pk }) => theme.mq.lg`
  background-image: url("${
    pk ? pkBg : bg
  }"), linear-gradient(-110.57deg, rgba(228, 255, 248, 0.1) -2.17%, #e4fff8 99.02%);
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-right: 100px;

  
  `}
`;

export const SocialLinks = styled(Flex)`
  list-style: none;
`;

export const SocialItem = styled.li`
  &:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const SocialLink = styled.a`
  svg {
    fill: ${({ theme }) => theme.colors.secondary};
    height: 4rem;
    width: 4rem;
    cursor: pointer;
  }
`;

export const TelAndMail = styled.div`
  display: box;
  align-items: left;
  gap: 5px;
  svg {
    margin-right: 1rem;
  }
`;
