import React from "react";
import ArrowDown from "../../images/icons/arrow-down.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import styled, { keyframes } from "styled-components";
import { Container } from "../global";

const ArrowIndicator = () => {
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper href="#do-more">
          <ArrowDown />
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default ArrowIndicator;

const bounce = keyframes`
0% {
  transform: translateY(0);
}
50% {
  transform:  translateY(20px);
}
100% {
  transform:  translateY(0);
}
`;

const SectionWrapper = styled.section`
  padding: 40px 0 0;
  margin-top: 80px;

    ${({ theme }) => theme.mq.sm`
    margin-top: 40px;
  `}

  ${({ theme }) => theme.mq.md`
    margin-top: 40px;
  `}

  ${({ theme }) => theme.mq.xl`
  padding: 60px 0 50px 0;
  `}
`;

const ContentWrapper = styled(AnchorLink)`
  animation: ${bounce} 4s infinite forwards;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
