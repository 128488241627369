import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import { Heading, Text, Flex, Box } from "rebass/styled-components";
import { Container } from "../../global";
import Hero from "../hero";

import Padlock from "../../../images/icons/padlock.svg";
import Tick from "../../../images/icons/tick.svg";

export default function SecurityAndPrivacy() {
  return (
    <StyledBackgroundSection id="security-and-privacy">
      <Container>
        <FlexWrapper justifyContent="space-evenly">
          <Flex>
            <StyledBox>
              <HeadingWrapper variant="heading" lineHeight={[null, null, null, "55px"]}>
                {"We take your Security and \n Privacy seriously"}
              </HeadingWrapper>
              <Fade bottom>
                <SecurityItem
                  title="Advanced fraud detection"
                  description="Your data is stored and encrypted using strong cryptography. We scan our systems daily against security threats."
                >
                  <SvgWrapper>
                    <Tick />
                  </SvgWrapper>
                </SecurityItem>
                <SecurityItem
                  title="Your money is safe with us"
                  description="We are compliant with PCI DSS. Your transactions are always encrypted and secured."
                >
                  <SvgWrapper>
                    <Tick />
                  </SvgWrapper>
                </SecurityItem>
              </Fade>
            </StyledBox>
          </Flex>
          <ImageWrapper>
            <Padlock />
          </ImageWrapper>
        </FlexWrapper>
      </Container>
    </StyledBackgroundSection>
  );
}

const StyledBackgroundSection = styled(props => <Hero.SecurityBackground style={{ zIndex: 4 }} {...props} />)`
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  opacity: 0.2;
  position: relative;
  width: 100%;
  z-index: 4;
  padding: 60px 0 60px 0;

  ${({ theme }) => theme.mq.lg`
    padding: 120px 0 80px 0;  
  `}

  ${({ theme }) => theme.mq.md`
    padding: 120px 0 80px 0;  
  `}
`;

const FlexWrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mq.lg`
    flex-direction: row;
    justify-content: space-evenly;
  `}
`;

const StyledBox = styled(Box)`
  width: 100%;
  padding: 10px 0;

  ${({ theme }) => theme.mq.lg`
    margin-right: 60px;
    width: 600px;
  `}

  ${({ theme }) => theme.mq.md`
    padding: 60px 0;
  `}
`;

const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  svg {
    width: 100%;
  }
`;

const HeadingWrapper = styled(Heading)`
  text-align: center;
  margin: 2rem 0;
  color: ${({ theme }) => theme.colors.white.regular};

  ${({ theme }) => theme.mq.lg`
    text-align: left;
  `}
`;

const ImageWrapper = styled.div`
  display: none;

  svg {
    height: auto;
    width: 100%;
  }

  ${({ theme }) => theme.mq.lg`
    margin-bottom: 0;
    max-width: unset;
    display: flex;
  `}
`;

const BoxWrapper = styled.div`
  border-radius: 5px;
  display: flex;
  flex: 1;
  margin: 16px 0;
  padding: 30px 30px 0;
  width: 100%;

  ${({ theme }) => theme.mq.lg`
  padding: 30px 0px 0;
  max-width: 465px;
  `}
`;

const BoxText = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxIconWrapper = styled.div`
  margin-right: 26px;
`;

const SecurityItem = ({ title, description, children }) => (
  <BoxWrapper>
    <BoxIconWrapper>{children}</BoxIconWrapper>
    <BoxText>
      <Text variant="inlineHeader" fontWeight="700" as="p" mb="1.5rem" color="#fff" textAlign={["left", "left"]}>
        {title}
      </Text>
      <Text variant="body" as="span" color="#fff" lineHeight={[null, null, null, "32px"]} textAlign={["left", "left"]}>
        {description}
      </Text>
    </BoxText>
  </BoxWrapper>
);
