import React from "react";
import { Text, Flex } from "rebass/styled-components";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useLocationCountry } from "../../../../hooks";

export const Card = props => {
  const { isPakistan } = useLocationCountry();

  return (
    <CardItem>
      <ImageWrapper>
        <Icon as={props.icon} style={{ boxShadow: `${props.shadow}`, borderRadius: `${props.radius}` }} />
      </ImageWrapper>
      <Text variant="cardHeading" as="p" mt="36px" mb="1rem" textAlign="left">
        {props.title}
      </Text>
      <Text
        variant="body"
        as="span"
        mt="10px"
        mb="25px"
        sx={{
          fontSize: "15px",
          lineHeight: "30px",
          color: "#8497A4",
          // whiteSpace: "pre-line",
          width: ["100%", null, null, null, "260px"],
        }}
        textAlign="left"
      >
        {props.description}
      </Text>
      {props.email && props.pkemail ? (
        <Text
          as="a"
          color="secondary"
          mb="20px"
          sx={{
            textDecoration: "none",
          }}
          href={isPakistan ? props.pkemail : props.email}
        >
          {isPakistan ? props.pkemail : props.email}
        </Text>
      ) : null}
    </CardItem>
  );
};

const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  line-height: 80%;
  margin: 0 auto;
  padding: 24px 8px 40px;
  width: 100%;
  transition: all 0.5s ease-in-out;

  ${({ theme }) => theme.mq.md`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    line-height: 80%;
    margin: 0 auto;
    padding: 16px 24px 24px;
    transition: box-shadow 260ms ease-in-out;
    width: 100%;
    img {
      transition: all 200ms ease-in 100ms;
    }  
  `}
  ${({ theme }) => theme.mq.xl`
    width: calc(100% - 26px);
    transition: all 0.5s ease-in-out;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: ${({ theme }) => theme.colors.white.regular};
      box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.05);
      border-radius: 30px;
      
      p {
        transition: all 0.2s ease-in-out;
        font-weight: bold;
      }
      span {
        color: ${({ theme }) => theme.colors.header};
      }
    }
  `};
`;

const ImageWrapper = styled(Flex)`
  align-items: center;
  height: 100px;
  justify-content: flex-start;
  padding: 10px;
  width: 100px;

  ${({ theme }) => theme.mq.lg`
      width: 50%;
      padding: 0;
      margin: 0;
  `}
`;

const Icon = styled.svg`
  width: 50px;
  height: 50px;

  ${({ theme }) => theme.mq.lg`
  width: 50px;
  height: 50px;
  `}
`;

Card.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  shadow: PropTypes.string,
  radius: PropTypes.string,
  email: PropTypes.string,
  pkemail: PropTypes.string,
};
