import React from "react";
import styled from "styled-components";

import { Card } from "./card";

import BillsIcon from "../../../../images/do-more/pay-bills.svg";
import ConnectIcon from "../../../../images/do-more/connect.svg";
// import GetLoanIcon from "../../../../images/do-more/get-loans.svg";
import OfflineBankingIcon from "../../../../images/do-more/offline-banking.svg";
import SavingsIcon from "../../../../images/do-more/savings.svg";
import SecurityIcon from "../../../../images/do-more/secure.svg";

import { useLocationCountry } from "../../../../hooks";

export const Services = () => {
  const { isPakistan } = useLocationCountry();

  const benefitsList = [
    {
      icon: BillsIcon,
      title: "Pay Your Utility Bills",
      description:
        "Make your payments from the comfort \n of your homes or on-the-go. Pay for \n your cable TV, electricity, water, \n education, toll, tax, and more.",
      shadow: "0px 30px 50px #FFCE00",
      radius: "12px",
    },
    {
      icon: SecurityIcon,
      title: "Fast & Secure Transfer",
      description:
        "With OPay, you can send money to \n other banks for free and receive money \n in real-time. All you need is a phone \n number to open an account.*",
      shadow: "0px 30px 50px rgba(31, 198, 157, 0.6)",
      radius: "12px",
    },
    {
      icon: ConnectIcon,
      title: "Connect with Friends",
      description:
        "Chat with family and friends on the \n app. Send money to them with zero \n charges through our wallet-to-wallet \n payment system.",
      shadow: "0px 30px 50px rgba(107, 56, 245, 0.6)",
      radius: "12px",
    },
    // {
    //   icon: GetLoanIcon,
    //   title: "Get Instant Loans",
    //   description:
    //     "Never get stuck as you can receive \n instant money into your wallet to \n complete your transactions when you \n have insufficient balance.",
    //   shadow: "0px 30px 50px #FFCE00",
    //   radius: "12px",
    // },
    {
      icon: OfflineBankingIcon,
      title: "Offline Banking",
      description:
        "No smartphone? No problem! Our \n USSD service is here to help you. Dial \n *955# on your phone to carry out any \n transaction.",
      shadow: "0px 30px 50px rgba(31, 198, 157, 0.6)",
      radius: "12px",
    },
    {
      icon: SavingsIcon,
      title: "Savings",
      description:
        "Start saving smartly with any of our \n savings products and get up to 15% \n interest returns per annum. Withdraw \n your money at your convenience.",
      shadow: "0px 30px 50px rgba(107, 56, 245, 0.6)",
      radius: "12px",
    },
  ];

  const pkBenefitsList = [
    {
      icon: BillsIcon,
      title: "Pay Your Utility Bills",
      description:
        "Make your payments from the comfort \n of your homes or on-the-go. Pay for \n your cable TV, electricity, water, \n education, toll, tax, and more.",
      shadow: "0px 30px 50px #FFCE00",
      radius: "12px",
    },
    {
      icon: OfflineBankingIcon,
      title: "Receive Marchant Payments",
      description:
        "With OPay POS Terminal, you can \n receive payment from your customer \n made by any bank debit/credit card",
      shadow: "0px 30px 50px rgba(31, 198, 157, 0.6)",
      radius: "12px",
    },
    {
      icon: SecurityIcon,
      title: "Fast & Secure Transfer",
      description:
        "With OPay, you can send money to \n other banks for free and receive money \n in real-time. All you need is a phone \n number to open an account.*",
      shadow: "0px 30px 50px rgba(31, 198, 157, 0.6)",
      radius: "12px",
    },
  ];

  return (
    <Wrapper>
      <Grid>
        {isPakistan
          ? pkBenefitsList.map((benefit, index) => <Card {...benefit} key={index} />)
          : benefitsList.map((benefit, index) => <Card {...benefit} key={index} />)}
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  ${({ theme }) => theme.mq.md`
    grid-template-columns: repeat(2, 320px);
    gap: 20px 20px;
  `};

  ${({ theme }) => theme.mq.lg`
    grid-template-columns: repeat(2, 380px);
  `};

  ${({ theme }) => theme.mq.xl`
    grid-template-columns: repeat(3, 380px);
  `};
`;
