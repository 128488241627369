import React from "react";
import { Box, Heading } from "rebass/styled-components";
import { ProductCard } from "./productCard";
import { graphql, useStaticQuery } from "gatsby";

const BetterLife = () => {
  const data = useStaticQuery(graphql`
    query {
      agent: file(sourceInstanceName: { eq: "product" }, name: { eq: "agent" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pkagent: file(sourceInstanceName: { eq: "product" }, name: { eq: "pkagent" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pkmerchant: file(sourceInstanceName: { eq: "product" }, name: { eq: "pkmerchant" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      moneyHand: file(sourceInstanceName: { eq: "product" }, name: { eq: "money-hand" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      payBills: file(sourceInstanceName: { eq: "product" }, name: { eq: "bills" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const productList = [
    {
      key: "randi-1",
      title: "Add Money, Make Transfers, \n Pay Bills",
      subtitle:
        "Live your life on your terms and without limits. With an \n OPay account, you can add money to your wallet for free, \n within seconds and start making transfers to other wallets \n or any bank accounts for free.* Take control of how you \n make your bill and utility payments.",
      link: "#",
      image: data.agent.childImageSharp.fluid,
      pkimage: data.pkagent.childImageSharp.fluid,
    },
    // {
    //   key: "randi-2",
    //   title: "Get a Loan within Minutes",
    //   subtitle:
    //     "Our CreditMe product offers you the ability to make \n purchases in the OPay app even when you don’t have the \n funds at that moment. You can get a seven-day \n interest-free loan from CreditMe within minutes and make \n purchases or transfer to any account.**",
    //   link: "#",
    //   image: data.moneyHand.childImageSharp.fluid,
    // },
    {
      key: "randi-3",
      title: "Become an OPay Merchant",
      subtitle:
        "As an OPay Merchant, you have the power and control to make \n as much money as you can while providing financial \n services and helping your community grow. Join the OPay \n family now, get a free POS and start to scale your \n business. We offer the lowest charges in the market.",
      link: "/merchants",
      image: data.payBills.childImageSharp.fluid,
      pkimage: data.pkmerchant.childImageSharp.fluid,
    },
  ];

  return (
    <Box sx={{ background: "linear-gradient(180deg, #F8FBFF 0%, rgba(250, 251, 251, 0.0001) 100%);" }}>
      <Box m={"0 auto"} px="2rem" width="100%" maxWidth={["100%", "54rem", "72rem", "96rem", "120rem"]}>
        <Box maxWidth="54rem" mx="auto" mb={["8rem", null, null, "14rem"]}>
          <Heading textAlign="center" as="h3" mb="-0.5rem" pt={["8rem", null, null, "14rem"]}>
            A better life with OPay
          </Heading>
        </Box>
        <Box>
          {productList.map((product, i) => (
            <ProductCard key={product.key} imageOrder={i % 2} {...product} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default BetterLife;
