import React from "react";
import styled, { keyframes } from "styled-components";
import { Container } from "../../global";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { Heading, Box, Text } from "rebass/styled-components";

const OpayCard = () => {
  const data = useStaticQuery(graphql`
    query {
      opayCardImage: file(sourceInstanceName: { eq: "product" }, name: { eq: "card-hand" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      dottedCircle: file(sourceInstanceName: { eq: "product" }, name: { eq: "dotted-circle" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      donut: file(sourceInstanceName: { eq: "product" }, name: { eq: "donut" }) {
        childImageSharp {
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container id="do-more">
      <Box maxWidth="118rem" mx="auto" mb="6rem" mt="8rem">
        <Heading variant="heading" textAlign="center" mb="-0.5rem">
          OPay Cards, Your Passport To The{" "}
          <Text
            color="secondary"
            sx={{
              display: "inline",
              borderBottom: "2px solid #1DCF9F",
            }}
          >
            World
          </Text>
        </Heading>
        <Text
          as="p"
          fontSize={["14px", null, null, "18px"]}
          lineHeight="2"
          color="#2C3E50"
          mt="2rem"
          px="4rem"
          sx={{ textAlign: "center !important" }}
        >
          With our physical debit cards, you can make payments for goods, services and international subscriptions from
          your OPay wallet. Make offline or online payments seamlessly with your OPay card and partake in our Generous
          Reward Program.
        </Text>
      </Box>

      <ImageWrapper>
        <Image objectFit="contain" fluid={data.opayCardImage.childImageSharp.fluid} width="100%" alt="OPay Card" />
        <StyledDottedCircle objectFit="contain" fluid={data.dottedCircle.childImageSharp.fluid} width="100%" />
        <StyledDonut objectFit="contain" fluid={data.donut.childImageSharp.fluid} width="100%" />
      </ImageWrapper>
    </Container>
  );
};
export default OpayCard;

const phoneBounce = keyframes`
0% {
  transform: translateY(0);
}
50% {
  transform:  translateY(5px);
}
100% {
  transform:  translateY(0);
}
`;

const cardBounce = keyframes`
0% {
  transform: translateY(0);
}
50% {
  transform: translateY(5px);
}
100% {
  transform: translateY(0);
}
`;

const ImageWrapper = styled.div`
  max-width: 38rem;
  position: relative;
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.mq.md`
    display: flex;
  `}
`;

const Image = styled(Img)`
  width: 50%;
  margin: 0 auto;
  margin-bottom: -4rem;

  ${({ theme }) => theme.mq.sm`
      width: 40%;
      margin: 0 auto;
       margin-bottom: -4rem;
  `}

  ${({ theme }) => theme.mq.md`
      width: 60%;
      margin: 0 auto;
       margin-bottom: -4rem;
  `}

  ${({ theme }) => theme.mq.lg`
      width: 100%;
       margin-bottom: -4rem;
  `}


  ${({ theme }) => theme.mq.xl`
  width:100%;
   margin-bottom: -4rem;
  `}
`;

// circle
const StyledDonut = styled(Img)`
  animation: ${phoneBounce} 4s infinite forwards;
  position: absolute !important;
  top: 120px;
  left: 250px;
  width: 30px;

  ${({ theme }) => theme.mq.sm`
    top: 100px;
    left: 240px;
    width: 30px;
  `}

  ${({ theme }) => theme.mq.md`
    top: 150px;
    left: 260px;
    width: 30px
  `}

  ${({ theme }) => theme.mq.lg`
     top: 250px;
    left: 320px;
    width: 40px
  `}

  ${({ theme }) => theme.mq.xl`
   top: 250px;
    left: 320px;
    width: 50px
  `}
`;

// dotted cirlce
const StyledDottedCircle = styled(Img)`
  animation: ${cardBounce} 3s infinite forwards;
  position: absolute !important;
  left: 110px;
  top: 150px;
  width: 40px;

  ${({ theme }) => theme.mq.sm`
    left: 120px;
    top: 110px;
    width: 30px;
  `}

  ${({ theme }) => theme.mq.md`
     left: 100px;
    top: 180px;
    width: 40px;
  `}

  ${({ theme }) => theme.mq.lg`
     left: 40px;
    top: 350px;
    width: 60px;
  `}

  ${({ theme }) => theme.mq.xl`
    left: 40px;
    top: 350px;
    width: 60px;
  `}

  
`;
