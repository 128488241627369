import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import { useEffect } from "react";
import styled, { css } from "styled-components";

const randPos = max => Math.random() * max;
const translate = (x, y) => `translate3d(${x}px,${y}px,0)`;

const Child = styled.div`
  transition: all 0.5s;

  ${({ active }) =>
    active &&
    css`
      transform: scale(1.2);
    `}
`;

export const RandomPosition = ({ children, xMax, yMax, ...props }) => {
  const duration = 2500;
  const [running, setRunning] = useState(true);

  const [springProps, setMotion] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140, duration },
  }));

  useEffect(() => {
    let interval;
    if (running) {
      setMotion({ xy: [randPos(xMax), randPos(yMax)] });
      interval = setInterval(() => {
        setMotion({ xy: [randPos(xMax), randPos(yMax)] });
      }, duration);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [running]);

  const mouseOver = () => setRunning(false);

  const mouseLeave = () => setRunning(true);

  return (
    <div {...props}>
      <animated.div
        onMouseLeave={mouseLeave}
        onMouseOver={mouseOver}
        style={{ transform: springProps.xy.interpolate(translate) }}
      >
        <Child active={!running}>{children}</Child>
      </animated.div>
    </div>
  );
};

RandomPosition.propTypes = {
  xMax: PropTypes.number,
  yMax: PropTypes.number,
};

RandomPosition.defaultProps = {
  xMax: 50,
  yMax: 50,
};
